/* ======= GLOBAL STYLES ======= */

body {
  height: 100vh;
  width: 100vw;
  background-image: url("./img/Toxss-dna-background1920x1080.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

html {
  height: 100%;
}

.nav-item {
  position: relative;
}

ul.no-bullets {
  list-style-type: none;
}

.company-colors {
  background-color: #0f487f;
  border-bottom: 2px solid #c5c9ca;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.nav-text {
  color: #c5c9ca;
  padding-top: 3px;
}

.hidden {
  display: none;
}

.not-visible {
  visibility: hidden;
}

.signature {
  white-space: nowrap;
  overflow-x: auto;
}

.signature::-webkit-scrollbar {
  display: none;
}

@media (max-width: 767px) {
  .hidden-sm {
    display: none;
  }
}

@media (max-width: 991px) {
  .hidden-md {
    display: none;
  }
}

@media (max-width: 1200px) {
  .hidden-lg {
    display: none;
  }
}

@media (max-width: 1340px) {
  .hidden-xl {
    display: none;
  }
}

@media (max-width: 1750px) {
  .hidden-xxl {
    display: none;
  }
}

/* ======= PROJECT ======= */

.panel {
  width: 400px;
  padding: 2rem;
  border: solid 1px gray;
}

input:not([type="radio"]).checkbox-active {
  width: 25px;
}

.side-bar {
  max-height: 94vh;
  overflow-y: auto;
}

.side-bar::-webkit-scrollbar {
  display: none;
}

.header-text {
  font-size: 25px;
}

.list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.sub-list {
  list-style-type: none;
}

.first-list-item {
  padding: 9px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: black;
  background-color: #c5c9ca;
  border: 1px solid rgba(0, 0, 0, 0.125);
  white-space: nowrap;
}

div:nth-last-of-type(1).list-item {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.list-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  color: white;
  background-color: #0f487f;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.side-bar-item {
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.side-bar-item:hover {
  overflow: visible;
  word-wrap: break-word;
  white-space: normal;
  height: auto;
}

.side-bar-sub-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clickable {
  cursor: pointer;
}

.react-link {
  color: white;
  text-decoration: none;
}

.react-sub-link {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.white-background {
  background: white;
  height: 100vh;
}

.sub-list-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.workspace {
  background: white;
  width: 100vh;
  height: calc(100vh - 56px);
  overflow: auto;
  padding: 0;
  margin: 0;
}

.btn {
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.53);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.53);
}

div.card a {
  text-decoration: none;
}

.modal-wrapper {
  padding-left: 32px;
}

.sub-nav {
  padding: 0.5rem 0;
}

.editor-wrapper {
  width: 100%;
  height: 84vh;
  overflow-y: auto;
}

.document-button {
  padding: 0 2rem;
  border: 0.5px solid darkgrey;
}

.icon {
  height: 50px;
  width: 50px;
}

.user-text {
  color: red;
}

textarea {
  border-style: none;
  border-color: Transparent;
  overflow: auto;
  resize: none;
}

.description-text {
  width: 100%;
  font-weight: 500;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card:hover .description-text {
  width: 100%;
  font-weight: 500;
  border-radius: 5px;
  white-space: normal;
  overflow: visible;
  height: auto;
}

.bottom-fixed {
  margin: 1rem;
}

div h3 {
  color: black;
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div h3 a {
  color: black;
  text-decoration: underline;
}

.footer {
  padding: 0 1rem;
}

.card {
  margin: 1rem 0rem 0rem 0rem;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.53);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.53);
}

.clickable-card:hover {
  background-color: gray;
  cursor: pointer;
}

.card-body {
  padding: 10px;
}

.card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-title:hover {
  overflow: visible;
  word-wrap: break-word;
  white-space: normal;
  height: auto;
}

.card-text {
  white-space: nowrap;
  overflow: hidden;
}

.form-button {
  margin-left: 2rem;
}

.redirect-btn {
  margin: 2px;
}

.logo {
  margin-top: 4rem;
}

.dropdown-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #007bff;
}

.dropdown-link:hover {
  text-decoration: underline;
  color: #122e7a;
}

.published a {
  color: green;
}

.reviewed a {
  color: red;
}

.form {
  width: 100%;
}

input:not([type="radio"]) {
  width: 100%;
}

.form-group {
  width: 100%;
}

.login-btn {
  width: 100%;
}

@keyframes display {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.alert {
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 9999;
  animation-name: display;
  animation-duration: 0.25s;
}

.app-links-dropdown {
  margin: 0px 10px;
}

.app-links-dropdown-toggle {
  background-color: #104c7c;
  border-color: #104c7c;
}

.dropdown-selector {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  z-index: 9999;
  min-width: 10rem;
  list-style: none;
  background-color: #fff;
  border-radius: 0.25rem;
  display: none;
  padding: 0.25rem 0 0.25rem 0;
}

.dropdown-selector.show {
  display: block;
}

.dropdown-selector li:hover {
  background-color: #c5c9ca;
}

.navbar-link {
  border: none;
  background: none;
  outline: none !important;
}

.navbar-link:hover {
  color: #122e7a;
}

.navbar-sublink {
  border: none;
  background: none;
  outline: none !important;
  padding: 0 1rem 0 1rem;
  width: 100%;
  text-align: left;
  font-family: inherit;
}

.navbar-sublink:hover {
  background-color: #c5c9ca;
}

.toggle-input {
  height: 0;
  width: 0;
  display: none;
}

.toggle-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 25px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
  margin-top: 0.25rem;
}

.toggle-label:after {
  content: '';
  position: absolute;
  top: 1.25px;
  left: 1.25px;
  width: 22.5px;
  height: 22.5px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked+.toggle-label {
  background: #bada55;
}

input:checked+.toggle-label:after {
  left: calc(100% - 1.25px);
  transform: translateX(-100%);
}

.toggle-label:active:after {
  width: 32.5px;
}

.center-container {
  max-width: 1100px;
  width: calc(100% - 800px);
  margin-left: auto;
  margin-right: auto;
}

.display-overflow {
  overflow: auto;
}

@media (max-width: 1200px) {
  .center-container {
    width: calc(100% - 200px);
    margin-left: auto;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .center-container {
    width: 100%;
  }
}

.tab-list {
  border-bottom: 1px solid black;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  background-color: #f0f0f0;
  border: solid black;
  border-width: 1px 1px 0 1px;
  font-weight: bold;
}

.tab-list-item:hover {
  background-color: #e6883c;
  cursor: pointer;
}

.bordertest {
  -webkit-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.53);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.53);
}

.checkbox-selector:hover {
  background-color: gray;
}

.selected {
  background-color: darkblue;
  color: white;
}

.selected:hover {
  background-color: blue;
  color: white;
}

.select-table {
  max-height: 30vh;
  overflow-y: auto;
}

.max-display {
  height: 94vh;
  overflow-y: auto;
}

.center {
  margin: auto;
  margin-top: 1rem;
}

.anchor-link {
  color: #007bff;
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

.anchor-link:hover {
  color: #122e7a;
  cursor: pointer;
  text-decoration: underline;
}